<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div>
                <h1 class="text-4xl">Chapman Teller Portal</h1>
            </div>
        </div>

        <div>
            <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <button
                        class="bg-white w-full py-3 px-4 rounded-lg shadow border-2 border-green-400"
                    >
                        Cashpower
                    </button>
                </div>
                <div class="w-full md:w-1/3 px-3">
                    <button
                        class="bg-gray-100 w-full py-3 px-4 rounded-lg shadow cursor-not-allowed"
                        disabled
                    >
                        Send Money
                    </button>
                </div>

                <div class="w-full md:w-1/3 px-3">
                    <button
                        class="bg-gray-100 w-full py-3 px-4 rounded-lg shadow cursor-not-allowed"
                        disabled
                    >
                        Receive Money
                    </button>
                </div>
            </div>
            <hr />

            <cashpower :user="user" :serviceStatus="serviceStatus" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cashpower from './Services/Cashpower.vue';
import checkPermissionExist from '../../mixins/checkPermissionExist';

export default {
    middleware: 'auth',
    layout: 'tellerportal',
    mixins: [checkPermissionExist],

    components: {
        Cashpower,
    },

    data: () => ({
        isLoading: true,
        serviceStatus: true, // New state for service status
        show: false,
        label: 'Loading...',
        showReceipt: false,
        isComplate: '',
        receipt: null,
        confirmAmount: '',
    }),

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },
    methods: {
        // print() {
        //   window.print();

        //   this.clear = true;
        // },
        async checkServiceStatus() {
            try {
                const { data } = await axios.get('/service-status/NAWEC');

                this.serviceStatus = data.is_live;

                if (!this.serviceStatus) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Service Unavailable!',
                        text: data.message,
                    });
                    this.isLoading = false;
                    this.show = false;
                    return false;
                }
                return true;
            } catch (error) {
                console.error('Service status check failed', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Could not verify service status. Please try again later.',
                });
                this.isLoading = false;
                this.show = false;
                return false;
            }
        },
        clearForm() {
            this.form.amount = null;
            this.form.meterNumber = '';
            this.form.meterName = '';
            this.form.fee = '';
            this.form.cp_amount = '';
            this.form.conclearsumerResponse = null;
            this.showReceipt = false;
            this.confirmAmount = null;
            this.receipt = null;
            this.clear = false;
        },
    },

    mounted() {
        this.checkServiceStatus();

        if (this.user.data.worksheet_status) {
            this.$router.go(-1);
            Swal.fire({
                icon: 'warning',
                title: 'Worksheet already generated!',
                text: 'Please note that after generating a worksheet you are not allowed to make sales',
            });
            return false;
        }
    },

    created() {
        if (!this.checkPermissionExist(this.user.data, 'teller-service')) {
            this.$router.go(-1);
            return false;
        }
    },
};
</script>

<style>
@media print {
    body * {
        visibility: hidden;
    }

    #hide-to-print #hide-to-print * {
        visibility: hidden;
    }

    .__hide__to_print {
        visibility: hidden;
    }

    #section-to-print,
    #section-to-print * {
        width: 100mm;
        /* background: #333; */

        visibility: visible;
    }
    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}
</style>
